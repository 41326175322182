<template>
  <div class="message-container">
    <div class="message-title">
      <h2>Usuario no autorizado</h2>
      <h3 class="grey-text">
        Tu usuario no se encuentra autorizado para acceder a esta
        aplicación.
      </h3>
      <h4>
        Para más información podés comunicarte con el administrador.
      </h4>
      <div class="mt-8">
        <v-btn tile class="primary" @click.prevent="logout">CERRAR SESIÓN</v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Unauthorized",
  created() {
    if (!this.validUser) {
      return;
    }
    const router = this.$router;
    const defaultPath = '/'
    const target = this.target ?? defaultPath;
    const targetRoute = router.resolve(target).route;

    if (
      !targetRoute.matched.length ||
      (targetRoute.matched.length === 1 &&
        ["*", "/", ""].includes(targetRoute.matched[0].path))
    ) {
      return router.push(defaultPath);
    }
    return router.push({ path: targetRoute.path, query: targetRoute.query });
  },
  computed: {
    ...mapGetters(["validUser"]),
    target() {
      return this.$router.currentRoute?.query?.t;
    },
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style lang="scss" scoped>

.message-title {
  margin-bottom: 10px !important;
  padding-bottom: 25px !important;
  padding-top: 10px !important;
}

.message-container {
  text-align: center;
  margin-top: 13%;
}

.grey-text {
  color: grey;
}

</style>
